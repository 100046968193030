@import '../variables';
@import '../mixin';

.page-container.product {
    .write_review {
        margin-top: 12px;

        .title {
          font-size: 17px;
          font-weight: 500;
        }
    }

    .tabs_detail {
        margin-top: 25px;
    }

    .owl-carousel {
        position: relative;

        .discount {
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 4;
            background-color: rgba(55,182,22, 0.8);
            color: #fff;
            font-weight: 700;
            border-radius: 4px;
            padding: 8px 20px 6px 20px;
            font-size: 17px;
        }
    }

    .slides-container {
        border-radius: 5px;
        max-width: 100%;
        min-height: 549px;
        position: relative;

        picture {
            width: 100%;
            min-height: 549px;
            background: #fafafa;
            border-radius: 5px;

            img {
                max-width: 100%;
                max-height: 549px;
                margin: auto;
                z-index: 2;
            }
        }

        .bg {
            position: absolute;
            width: 100%;
            height: 549px;
            max-height: 549px;
            overflow: hidden;
            border-radius: 5px;

            picture {
                overflow: hidden;

                img {
                    width: 100%;
                    filter: blur(9px) opacity(0.4) grayscale(0.4);
                    z-index: 1;
                    max-width: 823px;
                    max-height: 2000px;
                    top: -50%;
                }
            }
        }
    }

    .sidebar {
        &.bottom {
            margin-top: 26px;
        }

        .offers-near {
            .head {
                position: relative;

                img {
                    width: 60px;
                    right: 20px;
                    top: -10px;
                    position: absolute;
                }
            }

            .main {
                padding: 16px 16px;

                .description {
                    margin-bottom: 36px;
                    font-weight: 400;
                    line-height: 23px;

                    a {
                        text-decoration: underline;
                        font-weight: 500;
                    }
                }
            }
        }

        .box_booking {
            .opt_order {
                border-bottom: none;
                margin-bottom: 0px;
            }

            .offer {
                color: $color-green;

                .percent {
                    padding-left: 4px;
                    font-weight: 600;
                }
            }

            &.main {
                height: 549px;

                .description {
                    font-size: 14px;
                    padding: 10px;
                    line-height: 22px;
                    height: 396px;

                    .tags {
                        margin: -3px 0px 10px -8px;
                        padding: 0px;

                        li {
                            margin: 0px;
                            list-style: none;
                            display: inline-block;

                            a {
                                color: #999;
                            }

                            a:hover {
                                background-color: $color-secondary !important;
                                color: white !important;
                            }
                        }
                    }

                    .text {
                        font-weight: 500;
                        margin-right: -8px;
                        padding-right: 8px;
                        overflow-y: auto;
                        max-height: 330px;

                        a {
                            text-decoration: underline;
                            font-weight: 500;
                        }

                        .extended {
                            margin-top: 26px;
                        }

                        .info {
                            margin-top: 12px;
                            font-size: 13px;
                            font-weight: 400;
                            color: #666;

                            .title {
                                display: block;
                                font-weight: 500;
                            }
                        }
                    }
                }

                .out-of-stock {
                    position: relative;
                    padding-left: 10px;
                    top: 14px;

                    .price, .price-original {
                        font-size: 17px;
                        font-weight: 600;

                        &.price-original {
                            text-decoration: line-through;
                            margin-right: 10px;
                        }

                        &.price {
                            color: $color-green;
                        }
                    }

                    .ribbon {
                        top: 0px;
                    }
                }

                .buy {
                    text-align: center;
                    display: table;
                    width: 100%;

                    .price-original {
                        text-decoration: line-through;
                        font-size: 20px;
                        margin-right: 30px;
                        font-weight: 600;
                        display: table-cell;
                        vertical-align: middle;
                        text-align: right;
                        width: 40%;
                    }

                    .quantity-input-container {
                        display: table-cell;
                        vertical-align: middle;
                        width: 60%;

                        .quantity-input {
                            width: 150px;

                            .loading-overlay {
                                height: 38px;
                            }

                            input {
                                top: 0px;
                                height: 38px;
                                font-size: 17px;
                                font-weight: 600;
                                color: #111;
                                -webkit-text-fill-color: #111;
                            }

                            .btn_1 {
                                background-color: transparent;
                                border-color: $color-secondary;
                                color: #111;
                                min-height: 38px;
                                font-size: 17px;

                                &:hover, &.processing {
                                    background-color: $color-secondary;
                                    color: white;

                                    img {
                                        filter: brightness(0) invert(1);
                                    }
                                }

                            }

                            .added-container {
                                button {
                                    background-color: $color-secondary;
                                    color: white;

                                    &.sign {
                                        span {
                                            top: -1px;
                                            font-weight: 600;
                                            font-size: 17px;
                                        }
                                    }

                                    &:disabled {
                                        background-color: #eee;
                                        border-color: #777;
                                        color: #777;
                                    }
                                }
                            }

                            &.not-added {
                                top: -6px;
                            }

                            &.added {
                                top: -2px;
                            }
                        }
                    }
                }
            }

            &.delivery {
                background-color: #f0f0f0;
                padding: 15px;

                table {
                    width: 100%;
                    font-size: 15px;

                    th {
                        padding-bottom: 10px;
                        text-align: center;

                        img {
                            margin-top: -2px;
                            height: 18px;
                            margin-left: 12px;
                            margin-right: 4px;
                        }
                    }

                    td {
                        font-size: 14px;
                        text-align: center;

                        span {
                            font-size: inherit;
                        }
                    }
                }
            }
        }
    }

    .slide {
        max-width: 450px;
        min-height: inherit;
        margin: auto;
        text-align: center;

        @include mobile {
            min-height: 250px;
        }
    }

    .nav-item {
        .nav-link {
            padding-bottom: 36px;
            font-size: 19px;
        }
    }

    .offers-near {
        .head {
            border-bottom: 2px solid #ededed;
            font-size: 19px;
            padding-left: 12px;
            padding-bottom: 6px;
            margin-bottom: 20px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .other_info {
        border-radius: 5px;
        background-color: transparent;
    }

    @media (min-width: $large-device-width) {
		.product-card {
			&:not(:hover) {
				picture {
					img {
						filter: grayscale(50%);
					}
				}

				.actions {
					.not-added-container {
						button {
							background-color: transparent;
							border-color: transparent;
							color: $color-text;
						}
					}
				}
			}
		}
	}

    @media (min-width: ($medium-device-width + 1)) {
    }

    @media (max-width: $medium-device-width) {
        .slides-container {
            min-height: auto;

            .bg {
                display: none;
            }
        }

        .sidebar {
            &.top {
                margin-top: 20px;

                .box_booking {
                    height: auto;

                    .main {
                        height: auto;

                        .description {
                            height: auto;

                            .text {
                                height: auto;
                            }
                        }

                        .buy {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $small-device-width) {
        .other_info {
            h2 {
                font-size: 21px;
            }

            h3 {
                font-size: 16px;
            }

            p {
                font-size: 13px;
            }

            .delivery {
                padding-left: 5%;

                & > div {
                    float: left;
                    width: 45%;
                    margin: 0px;
                    padding: 0px 10px;
                    text-align: center;
                    text-indent: 20px;

                    &::before {
                        left: -13px;
                    }
                }

                &::after {
                    content: "";
                    display: block;
                    clear: both;
                }
            }
        }
    }
}